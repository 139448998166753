import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function DefaultNavbarLink({ icon, name, route, href, target }) {
  return (
    <>
      {href ? (
        <VuiBox
          component="a"
          href={href}
          target={target}
          rel="noopener noreferrer"
          mx={1}
          p={1}
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer", userSelect: "none" }}
        >
          <Icon
            sx={{
              color: ({ palette: { white } }) => white.main,
              verticalAlign: "middle",
            }}
          >
            {icon}
          </Icon>
          <VuiTypography
            variant="button"
            fontWeight="medium"
            color="white"
            sx={{ lineHeight: 0 }}
          >
            {name}
          </VuiTypography>
        </VuiBox>
      ) : (
        <VuiBox
          component={Link}
          to={route}
          mx={1}
          p={1}
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer", userSelect: "none" }}
        >
          <Icon
            sx={{
              color: ({ palette: { white } }) => white.main,
              verticalAlign: "middle",
            }}
          >
            {icon}
          </Icon>
          <VuiTypography
            variant="button"
            fontWeight="medium"
            color="white"
            sx={{ lineHeight: 0 }}
          >
            {name}
          </VuiTypography>
        </VuiBox>
      )}
    </>
  );
}

DefaultNavbarLink.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  route: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
};

DefaultNavbarLink.defaultProps = {
  route: "",
  href: "",
  target: "_self",
};

export default DefaultNavbarLink;