// Vision UI Dashboard React Base Styles
import colors from "assets/theme/base/colors";

const { info, dark } = colors;
export default {
  html: {
    scrollBehavior: "smooth",
    background: dark.body,
  },
  body: {
    backgroundImage: "linear-gradient(to right bottom, #000000, #18070f, #240d1c, #2e102a, #35153c, #351644, #35184c, #321b55, #2f1852, #2c154f, #29114c, #260e49)",
    backgroundSize: "cover",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
};