import React from "react";

import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import gif from "assets/images/cardimgfree.png";

const WelcomeMark = () => {
  return (
    <Card sx={() => ({
      height: "100%",
      py: "32px",
      backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: `url(${gif})`,
        backgroundSize: "cover",
        backgroundPosition: "50%",
        filter: "blur(8px)", // Adjust blur amount as needed
        zIndex: -1, // Make sure the blur is behind the content
      },
    })}>
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between" zIndex={1}>
        <VuiBox>
          <VuiTypography color="text" variant="button" fontWeight="regular" mb="12px">
            Welcome to
          </VuiTypography>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="18px">
            Swarm Network [stats]
          </VuiTypography>
          <VuiTypography color="text" variant="h6" fontWeight="regular" mb="auto">
            SWARM Collective Intelligence Network represents a novel approach to uncovering the global power of Collective Human Intelligence and decentralized decision-making by marrying artificial intelligence, human conversational data and prediction markets.
            <br /> <br /> Here you will be able to see some of the Swarm Network data in action.
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
