import { Client, Account, Databases, Query, Storage } from 'appwrite';

const client = new Client();

client
    .setEndpoint('https://appwrite.swarmnetwork.ai/v1')
    .setProject('66f3cb31001fc7f3545b');

const account = new Account(client);
const databases = new Databases(client);
const storage = new Storage(client);

export { client, account, databases, Query, storage };