// barChartOptionsDashboard.js

export const stackedBarChartOptionsDashboard = {
    chart: {
        type: "bar",
        stacked: true, // Enable stacking
        toolbar: {
            show: false,
        },
    },
    colors: [
        "#FF5733", // celebrity
        "#33FF57", // crypto
        "#3357FF", // culture
        "#FF33A1", // e-sports
        "#FF8C33", // meme coins
        "#33FFF5", // politics
        "#8C33FF", // sports
    ],
    xaxis: {
        categories: [
            "Sep 13",
            "Sep 14",
            "Sep 15",
            "Sep 16",
            "Sep 17",
            "Sep 18",
            "Sep 19",
            "Sep 20",
            "Sep 21",
            "Sep 22",
            "Sep 23",
            "Sep 24",
        ],
        labels: {
            show: true,
            style: {
                colors: "#fff",
                fontSize: "10px",
            },
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
    yaxis: {
        show: true,
        labels: {
            style: {
                colors: "#fff",
                fontSize: "10px",
                fontFamily: "Plus Jakarta Display",
            },
        },
    },
    grid: {
        show: true,
    },
    fill: {
        opacity: 1,
    },
    dataLabels: {
        enabled: false,
    },
    plotOptions: {
        bar: {
            borderRadius: 5,
            columnWidth: "20px",
        },
    },
    legend: {
        position: "bottom",
        horizontalAlign: "left",
        offsetX: 0,
        labels: {
            colors: "#fff",
        },
    },
    grid: {
        strokeDashArray: 5,
        borderColor: "#56577A",
    },
    tooltip: {
        theme: "dark",
        style: {
            fontSize: "10px",
            fontFamily: "Plus Jakarta Display",
        },
        onDatasetHover: {
            style: {
                fontSize: "10px",
                fontFamily: "Plus Jakarta Display",
            },
        },
    },
    responsive: [
        {
            breakpoint: 768,
            options: {
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                    },
                },
            },
        },
    ],
};