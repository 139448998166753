import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { account } from 'layouts/authentication/appwrite';
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgSignIn from "assets/images/signInImage.png";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    const staticPassword = 'staticPassword123'; // Set your static password here
    console.log("Email:", email);
    console.log("Using static password for login.");

    try {
      // Try to create a session with the email and static password
      await account.createEmailPasswordSession(email, staticPassword);
      history.push('/dashboard');
    } catch (error) {
      if (error.code === 401) { // Unauthorized, meaning the user does not exist
        try {
          // Create a new account with the email and static password
          await account.create('unique()', email, staticPassword);
          // Log the user in with the new account
          await account.createEmailPasswordSession(email, staticPassword);
          history.push('/dashboard');
        } catch (createError) {
          console.error("Error creating account or session:", createError.message);
        }
      } else {
        console.error("Error creating session:", error.message);
      }
    }
  };

  return (
    <CoverLayout
      title="Welcome To Swarm"
      color="white"
      description="Enter your email to continue"
      premotto="SWARM STATS"
      motto="Future of Prediction Markets"
      image={bgSignIn}
    >
      <VuiBox component="form" role="form" onSubmit={handleLogin}>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="email"
              placeholder="Your email..."
              fontWeight="500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth type="submit">
            Continue
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;