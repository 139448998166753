/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, LinearProgress, Stack } from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Vision UI Dashboard React base styles
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
import ReferralTracking from "layouts/dashboard/components/ReferralTracking";

// React icons
import { IoGlobe, IoPerson, IoPersonAdd } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HotizontalBarChart";
import DoughnutChart from "examples/Charts/DoughnutCharts/DoughnutChart";
import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import { stackedBarChartDataDashboard } from "layouts/dashboard/data/stackedBarChartDataDashboard";
import { stackedBarChartOptionsDashboard } from "layouts/dashboard/data/stackedBarChartOptionsDashboard";
import { doughnutChartData } from "layouts/dashboard/data/doughnutChartdata";
import { doughnutChartOptions } from "layouts/dashboard/data/doughnutChartOptions";
import { databases } from 'layouts/authentication/appwrite';
import React, { useEffect, useState } from 'react';


function Dashboard() {
  const { gradients } = colors;
  const { cardContent } = gradients;

  const [swcBalance, setSwcBalance] = useState(null);
  const [activeUsers, setActiveUsers] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);
  const [totalPredictions, setTotalPredictions] = useState(null);
  const [userRetention, setUserRetention] = useState(null);
  const [organicUserReferral, setOrganicUserReferral] = useState(null);
  const [referralRewards, setReferralRewards] = useState(null);

  useEffect(() => {
    const fetchData = async (documentId, setState) => {
      try {
        const response = await databases.getDocument(
          '66f3fe18003961783804', // databaseId
          '66f3fe22001f7d0552f6', // collectionId
          documentId
        );
        const value = response.Value[0];
        setState(value);
      } catch (error) {
        console.error(`Failed to fetch data for document ${documentId} from Appwrite:`, error);
      }
    };

    fetchData('66f3fecd0005e8c08057', setSwcBalance);
    fetchData('66f3fee10038a896f3b7', setActiveUsers);
    fetchData('66f3ff66001bd609eb4a', setTotalUsers);
    fetchData('66f3ff71003ba31cdad2', setTotalPredictions);
    fetchData('66f3ff7f000eb12ef6f2', setUserRetention);
    fetchData('66f3ff8f0027457df645', setOrganicUserReferral);
    fetchData('66f3ff9f00153f59baa6', setReferralRewards);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total SWC Balance", fontWeight: "regular" }}
                count={swcBalance !== null ? `${swcBalance}` : "Loading..."}
                percentage={{ color: "success", text: "+55%" }}
                icon={{ color: "info", component: <IoWallet size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Active Users" }}
                count={activeUsers !== null ? `${activeUsers}` : "Loading..."}
                percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "info", component: <IoPerson size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Users" }}
                count={totalUsers !== null ? `${totalUsers}` : "Loading..."}
                percentage={{ color: "error", text: "-2%" }}
                icon={{ color: "info", component: <IoPersonAdd size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Predictions" }}
                count={totalPredictions !== null ? `${totalPredictions}` : "Loading..."}
                // percentage={{ color: "success", text: "+-5" }}
                icon={{ color: "info", component: <IoGlobe size="20px" color="white" /> }}
              />
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={5}>
              <WelcomeMark />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <SatisfactionRate userRetention={userRetention} />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <ReferralTracking organicUserReferral={organicUserReferral} referralRewards={referralRewards} />
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} xl={7}>

              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Predictions Overview
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      Participation per category
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ height: "310px" }}>

                    <BarChart
                      barChartData={stackedBarChartDataDashboard}
                      barChartOptions={stackedBarChartOptionsDashboard}
                    />
                  </VuiBox>

                </VuiBox>
              </Card>

            </Grid>



            <Grid item xs={12} lg={6} xl={5}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>

                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="0px">
                    Top Events
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      (+23%){" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        13 Sep 2024 - 24 Sep 2024
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ height: "310px" }}>
                    <HorizontalBarChart
                      barChartData={barChartDataDashboard}
                      barChartOptions={barChartOptionsDashboard}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>

        <VuiBox mb={3}>
          <Grid container spacing={3}>

            <Grid item xs={12} lg={6} xl={5}>
              <Card>
                <VuiBox>

                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Predictions Category
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      activated by users{" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        13 Sep 2024 - 24 Sep 2024
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox
                    mb="24px"
                    height="285px"
                  >
                    <DoughnutChart
                      doughnutChartData={doughnutChartData}
                      doughnutChartOptions={doughnutChartOptions}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>

            <Grid item xs={12} lg={6} xl={7}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Predictions analysis
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      By option type (Aggregated in SWC)
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ height: "310px" }}>
                    <LineChart
                      lineChartData={lineChartDataDashboard}
                      lineChartOptions={lineChartOptionsDashboard}
                    />
                  </VuiBox>

                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>







        {/* <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
