import React from 'react';
import { Card, Stack } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';
import { FaEllipsisH } from 'react-icons/fa';
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';

function ReferralTracking({ organicUserReferral, referralRewards }) {
	const { info, gradients } = colors;
	const { cardContent } = gradients;

	return (
		<Card
			sx={{
				height: '100%',
				background: linearGradient(gradients.cardDark.main, gradients.cardDark.state, gradients.cardDark.deg)
			}}>
			<VuiBox sx={{ width: '100%' }}>
				<VuiBox
					display='flex'
					alignItems='center'
					justifyContent='space-beetween'
					sx={{ width: '100%' }}
					mb='40px'>
					<VuiTypography variant='lg' color='white' mr='auto' fontWeight='bold'>
						Referral Tracking
					</VuiTypography>
					{/* <VuiBox
						display='flex'
						justifyContent='center'
						alignItems='center'
						bgColor='#22234B'
						sx={{ width: '37px', height: '37px', cursor: 'pointer', borderRadius: '12px' }}>
						<FaEllipsisH color={info.main} size='18px' />
					</VuiBox> */}
				</VuiBox>
				<VuiBox
					display='flex'
					sx={({ breakpoints }) => ({
						[breakpoints.up('xs')]: {
							flexDirection: 'column',
							gap: '16px',
							justifyContent: 'center',
							alignItems: 'center'
						},
						[breakpoints.up('md')]: {
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}
					})}>
					{/* <Stack
						direction='column'
						spacing='20px'
						// width='500px'
						// maxWidth='100%'
						sx={({ breakpoints }) => (
							{
								mr: 'none',
								minWidth: '55vw',
								[breakpoints.only('md')]: {
									mr: '75px',
									minWidth: '75px',

								},
								[breakpoints.only('xl')]: {
									width: '500px',
									minWidth: '55vw'
								},
								[breakpoints.up('xl')]: {
									width: '700px',
									minWidth: '75px'
								},
							})}> */}

					<VuiBox
						display='flex'
						width='100%'
						p='20px 22px'
						flexDirection='column'
						alignItems='center'
						sx={({ breakpoints }) => ({
							background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
							borderRadius: '20px',
							[breakpoints.up('xl')]: {
								// maxWidth: '110px !important'
							},
							[breakpoints.up('xxl')]: {
								// minWidth: '180px',
								// maxWidth: '100% !important'
							}
						})}>
						<VuiTypography color='text' variant='button' fontWeight='regular' mb='5px'>
							Organic User Referrals
						</VuiTypography>
						<VuiTypography color='white' variant='lg' fontWeight='bold'>
							{organicUserReferral !== null ? `${organicUserReferral} Users` : 'Loading...'}
						</VuiTypography>
					</VuiBox>
					<VuiBox
						display='flex'
						width='100%'
						p='20px 22px'
						flexDirection='column'
						alignItems='center'
						sx={({ breakpoints }) => ({
							background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
							borderRadius: '20px',
							[breakpoints.up('xl')]: {
								// maxWidth: '110px !important'
							},
							[breakpoints.up('xxl')]: {
								// minWidth: '180px',
								// maxWidth: '100% !important'
							}
						})}>
						<VuiTypography color='text' variant='button' fontWeight='regular' mb='5px'>
							Referral Rewards
						</VuiTypography>
						<VuiTypography color='white' variant='lg' fontWeight='bold'>
							{referralRewards !== null ? `${referralRewards} SWC` : 'Loading...'}
						</VuiTypography>
					</VuiBox>
					{/* </Stack> */}
					{/* <VuiBox sx={{ position: 'relative', display: 'inline-flex' }}>
						<CircularProgress
							variant='determinate'
							value={92}
							size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
							color='success'
						/>
						<VuiBox
							sx={{
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<VuiBox display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
								<VuiTypography color='text' variant='button' mb='4px'>
									Reward Retention
								</VuiTypography>
								<VuiTypography
									color='white'
									variant='d5'
									fontWeight='bold'
									mb='4px'
									sx={({ breakpoints }) => ({
										[breakpoints.only('xl')]: {
											fontSize: '32px'
										},
										[breakpoints.only('lg')]: {
											fontSize: '28px'
										},
										[breakpoints.only('md')]: {
											fontSize: '24px'
										},
										[breakpoints.only('sm')]: {
											fontSize: '20px'
										},
										[breakpoints.only('xs')]: {
											fontSize: '16px'
										}
									})}
								>
									92%
								</VuiTypography>
								<VuiTypography color='text' variant='button'>
									Collectors
								</VuiTypography>
							</VuiBox>
						</VuiBox>
					</VuiBox> */}
				</VuiBox>
			</VuiBox >
		</Card >
	);
}

export default ReferralTracking;
