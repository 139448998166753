import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { colors } from "@mui/material";
import { Opacity } from "@mui/icons-material";

const DoughnutChart = ({ doughnutChartData, doughnutChartOptions }) => {

    // Calculate the total
    const total = doughnutChartData.reduce((acc, value) => acc + value, 0);

    // Defensive programming: Ensure options are defined
    const safeOptions = {
        ...doughnutChartOptions,
        legend: {
            ...doughnutChartOptions.legend,
            formatter: doughnutChartOptions.legend?.formatter || ((val) => (val !== undefined && val !== null ? val.toString() : '')),
            onItemHover: {
                highlightDataSeries: false // Disable hover effect
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Total',
                            color: 'white', // Set the color of the total label
                            formatter: () => total.toString() // Return the total as a plain string
                        }
                    }
                }
            }
        },
        stroke: {
            width: 0 // Remove the white border around the pieces
        }
    };

    return (
        <ReactApexChart
            options={safeOptions}
            series={doughnutChartData}
            type="donut"
            width="100%"
            height="100%"
        />
    );
};

export default DoughnutChart;