/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function SimmmpleLogo({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 24C0 12.6863 0 7.02944 3.51472 3.51472C7.02944 0 12.6863 0 24 0H232C243.314 0 248.971 0 252.485 3.51472C256 7.02944 256 12.6863 256 24V232C256 243.314 256 248.971 252.485 252.485C248.971 256 243.314 256 232 256H24C12.6863 256 7.02944 256 3.51472 252.485C0 248.971 0 243.314 0 232V24Z" fill="#0C0C0C" />
      <path d="M72.793 92.7935H36V129.587H72.793V92.7935Z" fill="#863DFF" />
      <path d="M146.414 92.7932H183.207H220V56H183.207H146.414H109.621H72.7935V92.7932H109.621H146.414Z" fill="#863DFF" />
      <path d="M109.621 166.414H146.414H183.207V203.207H220V166.414L183.207 129.621H146.414H109.621H72.7935V166.414H109.621Z" fill="#863DFF" />
      <path d="M72.793 166.415H36V203.208H72.793V166.415Z" fill="#863DFF" />
    </svg>
  );
}
// Setting default values for the props of SimmmpleLogo
SimmmpleLogo.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the SimmmpleLogo
SimmmpleLogo.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SimmmpleLogo;
