/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

export const lineChartDataDashboard = [
  {
    name: "Bullish Options",
    data: [334985, 605663, 485997, 704180, 457459, 359481, 825266, 626047, 525034, 659179, 699903, 356813],
  },
  {
    name: "Concervative Options",
    data: [407859, 490565, 250529, 554902, 620841, 361496, 262148, 119235, 340439, 175985, 491180, 180547],
  },
];
