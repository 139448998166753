export const doughnutChartOptions = {
    chart: {
        type: 'donut',
        width: '100%',
        height: '100%',
    },
    labels: ["crypto", "politics", "sports", "celebrity", "e-sports", "culture", "meme coins"],
    legend: {
        show: true,
        position: "right",
        labels: {
            colors: 'white'
        },
        itemMargin: {
            horizontal: 10, // Adjust horizontal spacing
            vertical: 10    // Adjust vertical spacing
        }
    },


    responsive: [
        {
            breakpoint: 480,
            options: {
                chart: {
                    width: "100%",
                    height: "440px",
                },
                legend: {
                    position: "bottom",
                },
            },
        },
    ],
};