import React, { useEffect, useState } from 'react';
import { storage } from 'layouts/authentication/appwrite';

import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Document, Page, pdfjs } from 'react-pdf';

// Set the workerSrc for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function Dashboard() {
  const [fileUrl, setFileURL] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const fetchFileUrl = async () => {
      try {
        // List files in the specified bucket
        const response = await storage.listFiles('66f432d8000bcc1a4542');
        const files = response.files;

        // Find the PDF file
        const pdfFile = files.find(file => file.mimeType === 'application/pdf');

        if (pdfFile) {
          // Get the preview URL of the PDF file
          const previewUrl = storage.getFileView('66f432d8000bcc1a4542', pdfFile.$id);
          setFileURL(previewUrl.href);
        }
      } catch (error) {
        console.error('Error fetching file URL:', error);
      }
    };

    fetchFileUrl();
  }, []);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Callback when the document is successfully loaded
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // Compute PDF page width based on window size, with a maximum width
  const getPageWidth = () => {
    const maxWidth = 1190; // Maximum width of the PDF page in pixels (adjust as needed)
    const width = windowWidth * 0.9; // 90% of the window width
    return width > maxWidth ? maxWidth : width;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '90vh' }}>
        <VuiBox style={{ overflowY: 'auto', flexGrow: 1 }}>
          {fileUrl ? (
            <div>
              <style>
                {`
                .pdf-container {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 100%;
                  overflow-x: hidden;
                  margin: 0;
                  padding: 0;
                }
                .react-pdf__Page {
                  margin: 30px auto;
                }
              `}
              </style>
              <div className="pdf-container">
                <Document
                  file={fileUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={<div>Loading PDF...</div>}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={getPageWidth()}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  ))}
                </Document>
              </div>
            </div>
          ) : (
            <div>Loading Swarm Deck...</div>
          )}
        </VuiBox>
        <Footer />
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;