// barChartDataDashboard.js

export const stackedBarChartDataDashboard = [
    {
        name: "celebrity",
        data: [5, 8, 5, 2, 246, 201, 45, 52, 49, 56, 34, 27],
    },
    {
        name: "crypto",
        data: [270, 359, 386, 482, 415, 400, 569, 480, 407, 579, 458, 308],
    },
    {
        name: "culture",
        data: [7, 9, 6, 15, 13, 20, 12, 21, 17, 13, 15, 9],
    },
    {
        name: "e-sports",
        data: [7, 13, 13, 20, 15, 24, 7, 36, 31, 15, 19, 6],
    },
    {
        name: "meme coins",
        data: [26, 28, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        name: "politics",
        data: [225, 258, 320, 389, 162, 304, 104, 204, 182, 298, 303, 61],
    },
    {
        name: "sports",
        data: [16, 16, 25, 25, 15, 65, 239, 552, 535, 25, 31, 42],
    },
];